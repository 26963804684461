<template>
  <table :id="table_id" class="mx-table mx-table-grid__address mx-table-style__main">
    <thead>
    <tr class="table-address">
      <th data-type="text-short">{{ $t('company.country') }} <span class="resize-handle"></span></th>
      <th data-type="text-short">{{ $t('company.city') }} <span class="resize-handle"></span></th>
      <th data-type="text-long">{{ $t('company.postcode') }} <span class="resize-handle"></span></th>
      <th data-type="text-short">{{ $t('company.street') }} <span class="resize-handle"></span></th>
      <th data-type="text-short">{{ $t('company.main_address') }} <span class="resize-handle"></span></th>
    </tr>
    </thead>
    <tbody>
    <router-link tag="tr" :to="{name: 'address.edit', params: {'id': address.id, 'company_id': company_id}}" v-for="address in addresses" :class="{'active': address.is_main === 1 && addresses.length > 1}">
      <td>{{address.country}}</td>
      <td>{{address.city}}</td>
      <td>{{address.postcode}}</td>
      <td>{{address.street}}</td>
      <td>{{address.is_main == 1 ? $t('company.yes') : $t('company.no')}}</td>
    </router-link>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ['company_id', 'addresses', 'table_id'],
  mounted() {
    setTimeout(this.tableResizing, 300)
  },
  methods: {
    tableResizing() {
      const min = 150;
      const columnTypeToRatioMap = {
        numeric: 1,
        'text-short': 1.67,
        'text-long': 3.33,
      };

      const table = document.querySelector(`#${this.table_id}`);
      const columns = [];
      let headerBeingResized;

      const onMouseMove = (e) => requestAnimationFrame(() => {
        let horizontalScrollOffset = document.documentElement.scrollLeft;
        const width = (horizontalScrollOffset + e.clientX) - headerBeingResized.offsetLeft;

        const column = columns.find(({header}) => header === headerBeingResized);
        column.size = Math.max(min, width) + 'px';

        table.style.gridTemplateColumns = columns
            .map(({header, size}) => size)
            .join(' ');
      });

      const onMouseUp = () => {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
        headerBeingResized.classList.remove('header--being-resized');
        headerBeingResized = null;
      };

      const initResize = ({target}) => {
        headerBeingResized = target.parentNode;
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
        headerBeingResized.classList.add('header--being-resized');
      };

      document.querySelectorAll(`#${this.table_id} th`).forEach((header) => {
        const max = columnTypeToRatioMap[header.dataset.type] + 'fr';
        columns.push({
          header,
          size: `minmax(${min}px, ${max})`,
        });
        header.querySelector(`#${this.table_id} .resize-handle`).addEventListener('mousedown', initResize);
      });
    }
  }
}
</script>