<template>
  <div>
    <table v-if="persons.length > 0" :id="table_id" :class="table_class" class="mx-table mx-table-style__main">
      <thead>
      <tr>
<!--        <th data-type="text-short">{{ $t('company.person.table.identification') }} <span class="resize-handle"></span></th>-->
        <th data-type="text-short">{{ $t('company.person.table.position') }} <span class="resize-handle"></span></th>
        <th data-type="text-short">{{ $t(`user.gender.title`) }} <span class="resize-handle"></span></th>
        <th data-type="text-short">{{ $t('company.person.table.first_name') }} <span class="resize-handle"></span></th>
        <th data-type="text-short">{{ $t('company.person.table.last_name') }} <span class="resize-handle"></span></th>
        <th data-type="text-long">{{ $t('company.person.table.phone') }} <span class="resize-handle"></span></th>
        <th data-type="text-short">{{ $t('company.person.table.email') }} <span class="resize-handle"></span></th>
      </tr>
      </thead>
      <tbody>
      <router-link
          tag="tr"
          :to="{name: 'person.edit', params: {'id': item.id, 'company_id': company_id}}"
          v-for="item in persons"
          :key="item.id"
          :class="{'active': item.is_main === 1 && persons.length > 1}"
      >
<!--        <td>{{ item.identification }}</td>-->
        <td>{{ item.position }}</td>
        <td>{{ $t(`user.gender.${item.gender}`) }}</td>
        <td>{{ item.first_name }}</td>
        <td>{{ item.last_name }}</td>
        <td>{{ item.phone }}</td>
        <td>{{ item.email }}</td>
      </router-link>
      </tbody>
    </table>
    <strong v-else class="mx-notfound">{{ $t('company.person.table.not_found') }}</strong>
  </div>
</template>

<script>
export default {
  props: ['company_id', 'persons', 'table_id', 'table_class'],
  mounted() {
    setTimeout(this.tableResizing, 300)
  },
  methods: {
    tableResizing() {
      const min = 150;
      const columnTypeToRatioMap = {
        numeric: 1,
        'text-short': 1.67,
        'text-long': 3.33,
      };

      const table = document.querySelector(`#${this.table_id}`);
      const columns = [];
      let headerBeingResized;

      const onMouseMove = (e) => requestAnimationFrame(() => {
        let horizontalScrollOffset = document.documentElement.scrollLeft;
        const width = (horizontalScrollOffset + e.clientX) - headerBeingResized.offsetLeft;

        const column = columns.find(({header}) => header === headerBeingResized);
        column.size = Math.max(min, width) + 'px';

        table.style.gridTemplateColumns = columns
            .map(({header, size}) => size)
            .join(' ');
      });

      const onMouseUp = () => {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
        headerBeingResized.classList.remove('header--being-resized');
        headerBeingResized = null;
      };

      const initResize = ({target}) => {
        headerBeingResized = target.parentNode;
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
        headerBeingResized.classList.add('header--being-resized');
      };

      document.querySelectorAll(`#${this.table_id} th`).forEach((header) => {
        const max = columnTypeToRatioMap[header.dataset.type] + 'fr';
        columns.push({
          header,
          size: `minmax(${min}px, ${max})`,
        });
        header.querySelector(`#${this.table_id} .resize-handle`).addEventListener('mousedown', initResize);
      });
    }
  }
}
</script>