<template>
  <CabinetPage :preloader="preloader">
    <div class="mx-cabinet-page-header">
      <router-link :to="{name: 'company.all'}" class="btn btn-outline-secondary">
        <ph-caret-left :size="22"/>
      </router-link>
      <div class="mx-actions">
        <router-link class="btn btn-peepz-default" :to="{name: 'company.edit', params: {'id': company.id}}">
          <ph-pencil-simple :size="22"/>
        </router-link>
        <a v-if="user.multi_company" href="#" class="btn btn-peepz-default" @click.prevent="DeleteCompanyModal(true)">
          <ph-trash-simple :size="22" />
        </a>
      </div>
    </div>

    <div class="row">

      <div class="mx-cabinet-sidebar col-xxl-3 col-lg-3 col-md-4">
        <div class="mx-card">
          <div class="mx-list_element-info" style="align-items: center">
            <router-link :to="{name: 'faq.show', params: {'company_id': company.id}}" class="btn btn-peepz-accept btn-peepz-faq">FAQ</router-link>
          </div>
        </div>
        <div class="mx-card">
          <h5 class="mx-title_border mx-title_border-sidebar__first">
            <div>
              <ph-buildings :size="24"/>
              <span>{{ company.company_name }} </span>
            </div>
            <strong>{{ company.company_name_short }}</strong>
          </h5>
          <div class="mx-list_element-info">
            <strong>{{ $t('company.main_address') }}</strong>
            <div>
              <span>{{ company.company_name }}<br>{{ main_address.street }}<br>{{ main_address.postcode }} {{ main_address.city }}</span>
            </div>
          </div>
          <div class="mx-list_element-info">
            <strong>{{ $t(`company.opening_hours`) }}</strong>
            <div class="mx-date-day_of_week" v-if="preloader === false">
              <div class="mx-date-day_of_week__text">
                <strong v-for="element in opening_hours_elements">{{ $t(`company.day_of_week.${element}`) }}</strong>
              </div>
              <div class="mx-date-day_of_week__time">
                <span v-for="hour in opening_hours_elements" v-if="opening_hours[hour].open === '1'">
                  {{ opening_hours[hour].from === 'null' ? '__:__' : opening_hours[hour].from }} - {{ opening_hours[hour].to === 'null' ? '__:__' : opening_hours[hour].to }}
                </span>
                <span v-else class="mx-date-day_of_week__time-closed">{{ $t(`company.closed`) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-card">
          <h5 class="mx-title_border">
            <div>
              <ph-identification-card :size="24"/>
              <span>{{ user.first_name }} {{ user.last_name }}</span>
            </div>
          </h5>
<!--          <div class="mx-list_element-info">-->
<!--            <strong>{{ $t('company.customer_number') }}</strong>-->
<!--            <div>-->
<!--              <span>{{ user.identification }}</span>-->
<!--            </div>-->
<!--          </div>-->
          <div class="mx-list_element-info">
            <strong>{{ $t('company.email') }}</strong>
            <div>
              <span>{{ user.email }}</span>
            </div>
          </div>
          <div class="mx-list_element-info">
            <strong>{{ $t('company.company_phone') }}</strong>
            <div>
              <span>{{ company.company_phone }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="mx-cabinet-main col-xxl-9 col-lg-9 col-md-8">
        <div class="mx-card">
          <h5 class="mx-title_border">
            <div>
              <ph-map-pin :size="24"/>
              <span>{{ $t('company.locations') }}</span>
            </div>
            <router-link :to="{name: 'address.create', params: {'company_id': company.id}}" class="btn btn-peepz-success">
              <ph-plus-circle :size="22" />
              <span v-if="!mobile">{{ $t('company.new_location') }}</span>
            </router-link>
          </h5>
          <TableCompanyAddress
              table_class="mx-table-grid__address"
              table_id="mx-table-company-cad"
              :company_id="company.id"
              :addresses="company.addresses"
          />
        </div>

        <div class="mx-card">
          <h5 class="mx-title_border">
            <div>
              <ph-fingerprint :size="24" />
              <span>{{ $t('company.cad.subtitle') }}</span>
            </div>
            <router-link :to="{name: 'cad.create', params: {'company_id': company.id}}" class="btn btn-peepz-success">
              <ph-plus-circle :size="22" />
              <span v-if="!mobile">{{ $t('company.cad.btn.new') }}</span>
            </router-link>
          </h5>
          <TableCompanyCustomerAccessData
              table_class="mx-table-grid__cad"
              table_id="mx-table-company"
              :company_id="company.id"
              :cads="company.customer_access_data"
          >
            <router-link :to="{name: 'cad.create', params: {'company_id': company.id}}"
                         class="btn btn-peepz-success"
                         style="max-width: 50px; text-align: center; margin: auto"
            >
              <ph-plus-circle :size="22" />
            </router-link>
          </TableCompanyCustomerAccessData>
        </div>

        <div class="mx-card">
          <h5 class="mx-title_border">
            <div>
              <ph-notebook :size="24"/>
              <span>{{ $t('company.person.title') }}</span>
            </div>

            <b-dropdown id="dropdown-right" right
                        :text="(mobile === false) ? $t('company.person.dropdown.title') : ''"
                        size="sm" variant="btn btn-peepz-success" >
              <router-link :to="{name: 'person.create', params: {'company_id': company.id}}" class="dropdown-item">
                <span>{{ $t('company.person.dropdown.new_person') }}</span>
              </router-link>
              <router-link :to="{name: 'person.csv.import', params: {'company_id': company.id}}" class="dropdown-item">
                <span>{{ $t('company.person.csv.button_import') }}</span>
              </router-link>

            </b-dropdown>

<!--            <router-link :to="{name: 'person.create', params: {'company_id': company.id}}" class="btn btn-peepz-success">-->
<!--              <ph-plus-circle :size="22" />-->
<!--              <span v-if="!mobile">{{ $t('company.person.new_person') }}</span>-->
<!--            </router-link>-->

          </h5>
          <TableCompanyPerson
              table_class="mx-table-grid__person"
              table_id="mx-table-person"
              :company_id="company.id"
              :persons="company.persons"
          />
        </div>
<!--        <div class="mx-card">-->
<!--          <h5 class="mx-title_border">-->
<!--            <div>-->
<!--              <ph-notebook :size="24"/>-->
<!--              <span>FAQS</span>-->
<!--            </div>-->
<!--          </h5>-->
<!--          <div>-->
<!--            {{faq}}-->
<!--          </div>-->
<!--        </div>-->
      </div>

    </div>

    <!--  Modal  -->
    <b-modal ref="DeleteCompany" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
     <div class="mx-modal-prompt">
       <h3 class="mx-modal-prompt__title">{{ $t('company.prompt.company.title') }}</h3>
       <strong class="mx-modal-prompt__subtitle">{{ $t('company.prompt.company.subtitle') }}</strong>
       <div class="mx-modal-prompt__buttons">
         <button type="button" :disabled="button_disabled" class="btn btn-peepz-danger" @click="companyDelete">{{ $t('company.delete') }}</button>
         <button type="button" :disabled="button_disabled" class="btn btn-peepz-default" @click="DeleteCompanyModal(false)">{{ $t('company.abort') }}</button>
       </div>
     </div>
    </b-modal>

  </CabinetPage>
</template>

<script>

import CabinetPage from "@/components/main/CabinetPage";
import TableCompanyAddress from "@/components/tables/TableCompanyAddress";
import TableCompanyPerson from "@/components/tables/TableCompanyPerson";
import TableCompanyCustomerAccessData from "../../../components/tables/TableCompanyCustomerAccessData";
import {
  PhBuildings,
  PhIdentificationCard,
  PhPlusCircle,
  PhCaretLeft,
  PhPencilSimple,
  PhNotebook,
  PhMapPin,
  PhMapPinLine,
  PhTrashSimple,
  PhDatabase ,
  PhFingerprint
} from "phosphor-vue";

export default {
  components: {
    CabinetPage,
    TableCompanyAddress,
    TableCompanyPerson,
    TableCompanyCustomerAccessData,
    PhBuildings,
    PhIdentificationCard,
    PhPlusCircle,
    PhCaretLeft,
    PhPencilSimple,
    PhNotebook,
    PhMapPin,
    PhMapPinLine,
    PhTrashSimple,
    PhDatabase ,
    PhFingerprint
  },
  data() {
    return {
      mobile: false,
      button_disabled: false,
      edit_company: false,
      preloader: true,
      company: {},
      main_address: {},
      user: {},
      opening_hours: {},
      opening_hours_elements: ['mo', 'di', 'mi', 'do', 'fr', 'sa', 'so'],
      faq: []
    }
  },
  mounted() {
    this.mobile = window.innerWidth <= '768'
    this.getUser()
    this.getCompany()

    setTimeout(this.tableResizing, 300)
  },
  methods: {
    DeleteCompanyModal(boll) {
      if (boll) {
        this.$refs['DeleteCompany'].show()
      } else {
        this.$refs['DeleteCompany'].hide()
      }
    },
    companyDelete() {
      this.button_disabled = true
      this.$store.dispatch('companyDelete', this.company.id).then(() => {
        this.$router.replace({
          name: 'company.all'
        })
      }).catch(() => {
        this.button_disabled = false
      })
    },
    preload() {
      this.preloader = false;
    },
    getUser() {
      this.user = this.$store.getters.user
    },
    getCompany() {
      this.$store.dispatch('companies').then(() => {
        this.company = this.$store.getters.companies.find(company => company.id === parseInt(this.$route.params.id))

        if (undefined === this.company) {
          this.$router.replace({
            name: 'company.all'
          })
        }

        this.main_address = this.company.addresses.find(address => parseInt(address.is_main) === 1)
        this.opening_hours = this.getOpeningHours(this.main_address.opening_hours)
        this.preload()
      })
    },
    getOpeningHours(opening_hours) {
      if(typeof opening_hours === "object") {
        return opening_hours
      }
      return JSON.parse(opening_hours)
    }
  }
}
</script>
