<template>
  <div>
    <table v-if="cads.length > 0" :id="table_id" :class="table_class" class="mx-table mx-table-style__main">
      <thead>
      <tr>
        <th data-type="text-short">{{ $t('company.cad.title') }} <span class="resize-handle"></span></th>
        <th data-type="text-short">{{ $t('company.cad.url') }} <span class="resize-handle"></span></th>
        <th data-type="text-short">{{ $t(`company.cad.login`) }} <span class="resize-handle"></span></th>
        <th data-type="text-short">{{ $t('company.cad.password') }} <span class="resize-handle"></span></th>
        <th data-type="text-short">{{ $t('company.cad.description') }} <span class="resize-handle"></span></th>
        <th data-type="text-long">{{ $t('company.cad.is_active') }} <span class="resize-handle"></span></th>
        <th data-type="text-long"><span class="resize-handle"></span></th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="item in cads"
          :key="item.id"
      >
        <td>{{ item.title }}</td>
        <td>
          <a :href="item.url" target="_blank">{{ item.url }}</a>
        </td>
        <td>{{ item.login }}</td>
        <td>{{ item.password }}</td>
        <td :title="item.description">{{ item.description }}</td>
        <td>{{ item.is_active == 1 ? $t('company.yes') : $t('company.no') }}</td>
        <td>
          <router-link
              :to="{name: 'cad.edit', params: {'id': item.id, 'company_id': company_id}}"
          >
            <ph-pencil-simple :size="22"/>
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
    <strong v-else class="mx-notfound">
      {{ $t('company.cad.table.not_found') }}
      <slot></slot>
    </strong>

  </div>
</template>

<script>

import {
  PhPencilSimple,
} from "phosphor-vue";

export default {
  props: ['company_id', 'cads', 'table_id', 'table_class'],
  components: {PhPencilSimple},
  mounted() {
    setTimeout(this.tableResizing, 300)
  },
  methods: {
    tableResizing() {
      const min = 150;
      const columnTypeToRatioMap = {
        numeric: 1,
        'text-short': 1.67,
        'text-long': 3.33,
      };

      const table = document.querySelector(`#${this.table_id}`);
      const columns = [];
      let headerBeingResized;

      const onMouseMove = (e) => requestAnimationFrame(() => {
        let horizontalScrollOffset = document.documentElement.scrollLeft;
        const width = (horizontalScrollOffset + e.clientX) - headerBeingResized.offsetLeft;

        const column = columns.find(({header}) => header === headerBeingResized);
        column.size = Math.max(min, width) + 'px';

        table.style.gridTemplateColumns = columns
            .map(({header, size}) => size)
            .join(' ');
      });

      const onMouseUp = () => {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
        headerBeingResized.classList.remove('header--being-resized');
        headerBeingResized = null;
      };

      const initResize = ({target}) => {
        headerBeingResized = target.parentNode;
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
        headerBeingResized.classList.add('header--being-resized');
      };

      document.querySelectorAll(`#${this.table_id} th`).forEach((header) => {
        const max = columnTypeToRatioMap[header.dataset.type] + 'fr';
        columns.push({
          header,
          size: `minmax(${min}px, ${max})`,
        });
        header.querySelector(`#${this.table_id} .resize-handle`).addEventListener('mousedown', initResize);
      });
    }
  }
}
</script>
